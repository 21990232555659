<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{$t('common.api-tokens.title')}}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn
            class="mx-1"
            @click="refreshTokenSessions"
            :loading="btnLoading.refreshTokenSessions"
        >
          {{ $t('common.api-tokens.buttons.refreshTokenSessions') }}
        </v-btn>

        <v-btn color="primary" @click="setShowAddOrEditDialog(true)">
          {{ $t('common.api-tokens.buttons.add') }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              :label="$t('common.inputs.search')"
              clearable
              v-on:click:clear="clearInput"
              @keyup.enter.native="searchTrigger"
              outlined>
          </v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            style="min-height: 600px"
            sort-by="createdAt"
            :sort-desc="false"
            multi-sort
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Label -->
          <template v-slot:item.label="{item}">
            <span>{{ item.label || truncate }}</span>
          </template>

          <!-- Token -->
          <template v-slot:item.token="{item}">
            <span v-if="revealTokens.includes(item.id)">
              {{ item.token }}
              <ButtonIconCopy class="ml-1" :value="item.token" />
            </span>
            <span v-else>******</span>
          </template>

          <!-- Created at -->
          <template v-slot:item.createdAt="{item}">
            <span>{{ formatDatetime(item.createdAt) }}</span>
          </template>

          <!-- Actions -->
          <template v-slot:item.action="{item}">

            <!-- Go to account -->
            <v-btn icon :to="{name: 'Account', params: {id: item.account}}">
              <v-icon small>fas fa-external-link-alt</v-icon>
            </v-btn>

            <!-- Reveal btn -->
            <v-btn icon @click="toggleRevealToken(item.id)">
              <v-icon small v-if="revealTokens.includes(item.id)">fas fa-eye-slash</v-icon>
              <v-icon small v-else>fas fa-eye</v-icon>
            </v-btn>

            <!-- Delete -->
            <v-btn icon @click="setShowApiTokenDeleteDialog(true, item)">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main api token dialog -->
    <ApiTokenMainDialog
        v-if="showDialog.addOrEdit"
        :value="showDialog.addOrEdit"
        :item="currentApiToken"
        @close="(needRefresh) => setShowAddOrEditDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
        v-if="showDialog.delete"
        :value="showDialog.delete"
        :title="$t('common.api-tokens.deleteDialog.title')"
        @submit="deleteApiToken"
        @close="(needRefresh) => setShowApiTokenDeleteDialog(false, null, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "ApiTokensView",

  components: {
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
    ApiTokenMainDialog: () => import("@/components/Common/ApiTokens/ApiTokenMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog")
  },
  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      isLoaded: false,
      currentApiToken: null,
      revealTokens: [],

      showDialog: {
        addOrEdit: false,
        delete: false
      },

      btnLoading: {
        refreshTokenSessions: false
      },

      headers: [
        {
          text: this.$t('common.api-tokens.datatable.headers.label'),
          value: "label",
          sortable: true,
          width: "20%"
        },
        {
          text: this.$t('common.api-tokens.datatable.headers.token'),
          value: "token",
          sortable: false,
          width: "30%"
        },
        {
          text: this.$t('common.api-tokens.datatable.headers.createdAt'),
          value: "createdAt",
          sortable: true,
          width: "15%"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "10%"
        }
      ],
      URL_API: {
        GET_ALL: "/settings/api-tokens"
      }
    }
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t('common.api-tokens.pageTitle'));
  },

  methods: {
    deleteApiToken() {
      this.$http
          .delete(`/settings/api-tokens/${this.currentApiToken.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.api-tokens.deleteDialog.success'));
            this.setShowApiTokenDeleteDialog(false, null, true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtnSave = false;
          });
    },

    refreshTokenSessions() {
      this.btnLoading.refreshTokenSessions = true;
      this.$http
          .post(`/settings/api-tokens/refresh-sessions`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.api-tokens.refreshTokenSessions.success'));
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.btnLoading.refreshTokenSessions = false;
          });
    },

    setShowAddOrEditDialog(val, data = null, reloadData = false) {
      this.currentApiToken = data;
      if (reloadData)
        this.searchTrigger();
      this.showDialog.addOrEdit = val;
    },

    setShowApiTokenDeleteDialog(val, apiToken = null, reloadData = false) {
      this.currentApiToken = apiToken;
      if (reloadData)
        this.searchTrigger();

      this.showDialog.delete = val;
    },

    toggleRevealToken(apiTokenId = null) {
      const index = this.revealTokens.indexOf(apiTokenId);
      if (index > -1)
        this.revealTokens.splice(index, 1);
      else
        this.revealTokens.push(apiTokenId);
    }
  }
}
</script>

<style scoped>

</style>
