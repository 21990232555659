<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t('settings.title') }}</h1>
      </v-col>

      <!-- Settings panel -->
      <v-col cols="12" v-if="$route.name === 'SettingsPanel'">
        <v-row justify="start" class="ma-2" no-gutters>

          <v-col cols="12" md="4" v-for="(module) of items" :key="module.label">
            <v-card class="ma-1">
              <v-card-title>
                <v-icon height="50">{{module.icon}}</v-icon>
                <v-btn
                  color="primary"
                  class="font-weight-bold"
                  text
                  :to="module.route">
                  {{ module.label }}
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <!-- FM Signature generator -->
          <v-col cols="12" md="4">
            <v-card class="ma-1">
              <v-card-title>
                <v-icon height="50">fas fa-alien-monster</v-icon>
                <v-btn
                  color="primary"
                  class="font-weight-bold"
                  text
                  @click="toggleFMSignatureGeneratorDialog(true)"
                >
                  {{ $t('settings.modules.fm-signature-generator') }}
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

        </v-row>
      </v-col>

      <router-view v-else/>
    </v-row>

    <!-- FM Signature Generator Dialog -->
    <FMSignatureGeneratorMainDialog
      v-if="showDialog.fmSignatureGenerator"
      :value="showDialog.fmSignatureGenerator"
      :item="null"
      @close="toggleFMSignatureGeneratorDialog(false)"
    />

  </v-container>
</template>

<script>

export default {
  name: "SettingsView",

  components: {
    FMSignatureGeneratorMainDialog: () => import("@/components/Settings/DocumentsGenerator/FMSignature/FMSignatureGeneratorMainDialog")
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t('settings.pageTitle'))
  },

  data() {
    return {
      items: [
        {icon: "far fa-users-cog", route: {name: "Settings/Members"}, label: this.$t('settings.modules.members')},
        {icon: "far fa-sheep", route: {name: "Settings/RawMaterials"}, label: this.$t('settings.modules.raw-materials')},
        {icon: "far fa-key", route: {name: "Settings/ApiTokens"}, label: this.$t('settings.modules.api-tokens')},
      ],
      showDialog: {
        fmSignatureGenerator: false
      }
    }
  },

  methods: {
    toggleFMSignatureGeneratorDialog(val) {
      this.showDialog.fmSignatureGenerator = val;
    }
  }
}
</script>

<style scoped>

</style>