<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <Breadcrumbs2 :items="breadcrumbsList" />
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <BtnDatatableAdd
          :text="$t('settings.raw-materials.buttons.add')"
          @click="setShowAddDialog(true)"
        />
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              :label="$t('common.inputs.search')"
              clearable
              v-on:click:clear="clearInput"
              @keyup.enter.native="searchTrigger"
              outlined>
          </v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            style="min-height: 600px"
            sort-by="name"
            @click:row="goToOne"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Name -->
          <template v-slot:item.name="{item}">
            {{item.name}}
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main brand dialog -->
    <RawMaterialMainDialog
        v-if="showDialog.add"
        :value="showDialog.add"
        :item="null"
        @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from '@/mixins/vuetifyDataTable';
import formatter        from "@/mixins/formatter";

export default {
  name: "RawMaterialsView",

  components: {
    BtnDatatableAdd: () => import("@/components/Common/Buttons/BtnDatatableAdd"),
    Breadcrumbs2: () => import("@/components/Common/Bars/Breadcrumbs2"),
    RawMaterialMainDialog: () => import("@/components/Settings/RawMaterials/RawMaterialMainDialog")
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t('settings.raw-materials.datatable.headers.name'),
          value: "name",
          sortable: false,
          width: "40%"
        },
      ],
      URL_API: {
        GET_ALL: "/settings/raw-materials"
      },

      showDialog: {
        add: false,
      },
      isLoadingExportBtn: false,

      breadcrumbsList: [
        {text: this.$t('settings.title'), to: {name: 'SettingsPanel'}},
        {text: this.$t('settings.raw-materials.title')}
      ]
    }
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t('settings.raw-materials.pageTitle'));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData)
        this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Settings/RawMaterial", params: {id: obj.id}
      });
    },

  }
}
</script>

<style scoped>

</style>
