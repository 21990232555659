<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{$t('settings.fm-accounts.title')}}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="setShowAddDialog(true)">{{$t('settings.fm-accounts.buttons.add')}}</v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              :label="$t('common.inputs.search')"
              clearable
              v-on:click:clear="clearInput"
              @keyup.enter.native="searchTrigger"
              outlined>
          </v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            style="min-height: 600px"
            sort-by="name"
            @click:row="goToOne"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Picture -->
          <template v-slot:item.picture="{item}">
            <DisplayPicture
                :custom-width="50"
                :img="item.picture"
                :name="item.person.firstname" />
          </template>

          <!-- Name -->
          <template v-slot:item.name="{item}">
            {{item.person.firstname}} {{item.person.name}}
          </template>

          <!-- Email -->
          <template v-slot:item.email="{item}">
            {{item.user.email}}
          </template>

          <!-- Position -->
          <template v-slot:item.position="{item}">
            {{item.position}}
          </template>

          <!-- Role -->
          <template v-slot:item.role="{item}">
            {{item.user.role}}
          </template>

          <!-- Last login -->
          <template v-slot:item.last_login="{item}">
            <span v-if="item.user.last_login">{{
                $t('settings.fm-accounts.labels.user.last_login', {
                  date: formatDatetime(item.user.last_login)
                })
              }}</span>
            <span v-else>{{ $t('settings.fm-accounts.labels.user.never_connected') }}</span>
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{item}">

            <!-- Btn copy id -->
            <ButtonIconCopy
                :value="item.id"
                :tooltip-text="$t('settings.fm-accounts.datatable.actions.btnCopyId')"
            />
          </template>

          <!-- Enabled -->
          <template v-slot:item.enabled="{item}">
            <v-switch v-model="item.user.enabled" readonly />
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main brand dialog -->
    <MemberMainDialog
        v-if="showDialog.add"
        :value="showDialog.add"
        :item="null"
        @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from '@/mixins/vuetifyDataTable';
import formatter        from "@/mixins/formatter";
import ButtonIconCopy   from "../../../components/Common/Buttons/ButtonIconCopy";

export default {
  name: "MembersView",

  components: {
    ButtonIconCopy,
    DisplayPicture: () => import("@/components/Common/Picture/DisplayPicture"),
    MemberMainDialog: () => import("@/components/Settings/Members/MemberMainDialog")
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: "",
          value: "picture",
          sortable: false,
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.name'),
          value: "name",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.email'),
          value: "email",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.position'),
          value: "position",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.role'),
          value: "role",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.last_login'),
          value: "last_login",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.actions'),
          value: "actions",
          sortable: false,
          width: "5%"
        },
        {
          text: this.$t('settings.fm-accounts.datatable.headers.enabled'),
          value: "enabled",
          sortable: false,
          width: "5%"
        }
      ],
      URL_API: {
        GET_ALL: "/fm-accounts"
      },

      showDialog: {
        add: false,
      },
    }
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t('settings.fm-accounts.pageTitle'));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData)
        this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      /*this.$router.push({
        name: "Settings/Member", params: {id: obj.id}
      });
       */
    },

  }
}
</script>

<style scoped>

</style>
