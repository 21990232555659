<template>
  <v-container fluid>
    <v-row no-gutters>
      <Breadcrumbs2
          :items="breadcrumbsList" />

      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <!--v-chip>{{item.type}}</v-chip-->
                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>

              <v-divider class="mt-4 mx-2"/>
            </v-col>

            <!-- TODO General info -->
            <v-col cols="12" class="my-4">
              <p class="mx-2">{{item.description}}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Raw Material Main dialog -->
    <RawMaterialMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('settings.raw-materials.deleteDialog.title', {name: item.name})"
      @submit="deleteItem"
      @close="setShowDeleteDialog(false)"
    />
  </v-container>
</template>

<script>

export default {
  name: "RawMaterialView",

  components: {
    Breadcrumbs2: () => import("@/components/Common/Bars/Breadcrumbs2"),
    RawMaterialMainDialog: () => import("@/components/Settings/RawMaterials/RawMaterialMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog")
  },

  data() {
    return {
      showDialog: {
        main: false,
        delete: false
      },

      isLoaded: false,
      isLoading: {
        btnSave: false
      },

      item: null,
      breadcrumbsList: [
        {text: this.$t('settings.title'), to: {name: 'SettingsPanel'}},
        {text: this.$t('settings.raw-materials.title'), to: {name: 'Settings/RawMaterials'}},
      ]
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {

      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
          .get(`/settings/raw-materials/${this.$route.params.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.item = res.data;
            this.$store.commit("pageHead/setTitle", `${this.item.name}`);
            this.setBreadcrumbsList(2, {text: this.item.name})
          })
          .catch(err => {
            this.$store.commit("toast/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoaded = true;
          })
    },

    deleteItem() {
      this.$http
          .delete(`/settings/raw-materials/${this.item.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('settings.raw-materials.deleteDialog.success'));
            this.setShowDeleteDialog(false);
            this.$router.push({name: "Settings/RawMaterials"});
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtnSave = false;
          });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData)
        this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData)
        this.getData();

      this.showDialog.delete = val;
    },

    setBreadcrumbsList(index, obj) {
      if (this.breadcrumbsList.length > index)
        this.breadcrumbsList[index] = obj;
      else
        this.breadcrumbsList.push(obj);
    }
  }
}
</script>

<style scoped>

</style>