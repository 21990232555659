<template>
  <v-container fluid>
    <v-row v-if="isLoaded" no-gutters class="mt-6">

      <!-- LEFT PANEL -->
      <v-col cols="12" class="mt-6">
        <v-row class="white elevation-3 mx-2" no-gutters>
          <v-col cols="12" style="background-color: rgb(245, 245, 245);">
            <v-row justify="space-between" no-gutters>
              <v-spacer/>

              <!-- Edit button -->
              <BtnIconEdit
                  @click="setShowMainEditDialog(true)"
                  class="mr-2 mt-2"/>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="my-4">

              <!-- Picture -->
              <v-col cols="12" md="3">
                <DisplayPictureEditable
                    v-if="item.person"
                    class="ma-2"
                    :img="item.person.img"
                    :name="`${item.person.firstname} ${item.person.name}`"
                    @changed="pictureChanged"/>
              </v-col>

              <!-- Name -->
              <v-col cols="12" md="9" class="text-center">
                <h1>{{item.person.firstname}} {{item.person.name}}</h1>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-2">
            <v-row no-gutters>
              <div class="my-4">
                <ProfilePreferences
                    :preferences="item.preferences"
                    :registration_date="null"
                />
              </div>
            </v-row>
          </v-col>

        </v-row>
      </v-col>

      <!-- RIGHT PANEL -->
      <v-col cols="12" class="mt-6">
        <v-row no-gutters>
          <!-- Display details -->

          <!-- Password -->
          <v-col cols="12" md="6" class="mt-6">
            <div class="font-weight-bold mx-2">
              <v-hover>
                <v-card slot-scope="{hover}" :class="`elevation-${hover ? 5 : 2}`" style="min-height: 100px;">
                  <v-row justify="space-between" no-gutters>
                    <h3 class="ml-3 mt-3">{{$t('profile.password.texts.cardTitle')}}</h3>
                    <div>
                      <BtnIconEdit v-on:click="setShowPasswordEditDialog(true)" class="mr-2"/>
                    </div>
                  </v-row>

                  <!--div v-if="item.recovery.last_password_modification" class="detail-text-label mt-2 ml-3">
                    {{$t('profile.password.texts.last_password_modification')}}
                    {{datetimeEventsFormatter(item.recovery.last_password_modification)}}
                  </div>
                  <div v-else class="text-label mt-2 ml-3">{{$t('profile.password.texts.never_modified')}}
                  </div-->
                </v-card>
              </v-hover>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Main Account Dialog -->
    <ProfileMainDialog
        v-if="showDialog.main"
        :value="showDialog.main"
        :item="{person: item.person, preferences: item.preferences}"
        @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Email dialog -->
    <ProfileEmailDialog
        v-if="showDialog.mainEmail"
        :value="showDialog.mainEmail"
        :item="{email: item.email}"
        @close="(needRefresh) => setShowMainEmailEditDialog(false, needRefresh)"
    />

    <!-- Password dialog -->
    <ProfilePasswordDialog
        v-if="showDialog.password"
        :value="showDialog.password"
        @close="(needRefresh) => setShowPasswordEditDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
export default {
  name: "MemberView",

  components: {
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    DisplayPictureEditable: () => import("@/components/Common/Picture/DisplayPictureEditable"),
    ProfilePreferences: () => import("@/components/Profile/Preferences"),
    ProfileMainDialog: () => import("@/components/Profile/ProfileMainDialog"),
    ProfilePasswordDialog: () => import("@/components/Profile/ProfilePasswordDialog"),
    ProfileEmailDialog: () => import("@/components/Profile/ProfileEmailDialog")
  },

  data()
  {
    return {
      showDialog: {
        main: false,
        password: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false
      },

      item: null,
      URL_API: {
        EDIT_PICTURE: `/fm-accounts/${this.$route.params.id}/picture`
      }
    }
  },

  created() {
    this.getData();
  },


  methods: {

    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
          .get(`/fm-accounts/${this.$route.params.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res =>
          {
            this.item = res.data;

            this.$store.commit("pageHead/setTitle", `${this.item.person.firstname} ${this.item.person.name}`);
          })
          .catch(err => {
            this.$store.commit("toast/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoaded = true;
          })
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData)
        this.getData();
      this.showDialog.main = val;
    },

    setShowPasswordEditDialog(val, reloadData = false) {
      if (reloadData) {
        this.getData()
      }
      this.showDialog.password = val;
    },

    pictureChanged() {
      this.getData();
    }
  },
}
</script>

<style scoped>

</style>
